function PasswordInputFieldConfirm({
  handleValidation,
  handlePasswordChange,
  confirmPasswordValue,
  confirmPasswordError,
}) {
  return (
    <>
      <input
        autoComplete="off"
        name="confirmPassword"
        onChange={handlePasswordChange}
        onBlur={handleValidation}
        placeholder="Confirm"
        type="password"
        value={confirmPasswordValue}
      />
      <em className="text-danger">{confirmPasswordError}</em>
    </>
  );
}
export default PasswordInputFieldConfirm;
