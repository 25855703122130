/* Button.js */

import React, { useContext } from "react";
import { Context } from "../Context";
import { useNavigate } from "react-router-dom";

const Button = (props) => {
  const { label, disabled, addClasses, action, id, table } = props;
  const {
    setProfile,
    profile,
    setStaleUserSaved,
    setStaleUserCreate,
    setIsEditMode,
    setIsSectionDataStale,
    setIsIngredientDataStale,
    setAwardCount,
  } = useContext(Context);

  const navigate = useNavigate();

  const handleActions = () => {

    const navigateTo = (path) => {
      try {
        navigate(path);
      } catch (error) {
        console.error("Navigation error:", error);
      }
    };

    switch (action) {
      case "handleBasket":
        navigateTo("/basket");
        break;
      case "handlePostDish":
        navigateTo("/dish/create");
        break;
      case "handleLogout":
        handleLogout();
        break;
      case "handleDashboard":
        navigateTo("/dashboard");
        break;
      case "handleLogin":
        navigateTo(`/login`);
        break;
      case "handlePreviewDish":
        navigateTo(`/${table}/${id}`);
        break;
      case "handleEditDish":
        if (profile.username) {
          setIsEditMode(true);
          navigateTo(`/${table}/edit/${id}`);
        }
        break;
      case "handleRenameDish":
        navigateTo(`/dish/create/${id}`);
        break;
      case "handleChangePassword":
        navigateTo(`/login`);
        break;
      default:
        break;
    }
    setIsSectionDataStale(true);
    setIsIngredientDataStale(true);
  };

  const handleLogout = () => {
    if (window.confirm("Logout! Are you sure?")) {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      setStaleUserSaved(true);
      setStaleUserCreate(true);
      setProfile({});
      setIsEditMode(false);
      setAwardCount(0);
      navigate("/login");
    }
  };

  return (
    <button
      name="btn-dish-builder"
      aria-label={label}
      className={`button-cta ` + addClasses}
      onClick={() => handleActions()}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
export default Button;
