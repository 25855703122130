/* Dish.js */

import React, { lazy, Suspense, useState } from "react";
import { renderLoader } from "../utils/renderLoader";

const Screen = lazy(() => import("./../components/Screen"));
const Slider = lazy(() => import("./../components/Slider"));

const Dish = () => {
  const [filter, setFilter] = useState("");
  return (
    <main>
      <Suspense fallback={renderLoader()}>
        <Screen table="dish" setFilter={setFilter} />
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <Slider table="dish" filter={filter} />
      </Suspense>
    </main>
  );
};

export default Dish;
