export const config = {
    apiRoot: process.env.REACT_APP_API_URI,
    REACT_APP_GREENHOUSE_URI: process.env.REACT_APP_GREENHOUSE_URI + "/images",
    uploadRoot: process.env.REACT_APP_GREENHOUSE_URI,
    marketRoot: process.env.REACT_APP_MARKET_ANALYSER_URI,
    salt: process.env.REACT_APP_SALT,
    REACT_APP_AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    REACT_APP_AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    REACT_APP_AWS_REGION: process.env.REACT_APP_AWS_REGION,
    REACT_APP_AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
    REACT_APP_CLOUDFRONT_URL: process.env.REACT_APP_CLOUDFRONT_URL
}