/* Search.js */

import SearchList from '../components/SearchList';

const Search = (props) => { 
  const { table } = props;
  return <main className="screen">
    <SearchList table={table} />
  </main>};

export default Search;