// ImageUpload.js

import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { config } from "../../config";

const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileKey, setFileKey] = useState('');
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: config.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: config.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: config.REACT_APP_AWS_REGION // Region of your S3 bucket
  });

  const s3 = new AWS.S3();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setMessage('Please select a file to upload.');
      return;
    }
  
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!allowedTypes.includes(selectedFile.type)) {
      setMessage('Only JPEG, PNG, GIF, and WEBP files are allowed.');
      return;
    }
  
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSize) {
      setMessage('File size exceeds the 5MB limit.');
      return;
    }
  
    // Sanitize file name
    const sanitizedFileName = selectedFile.name.replace(/[^a-zA-Z0-9.-]/g, '_');

    setUploading(true);
    setMessage('');
    setImageUrl('');

    const params = {
      Bucket: config.REACT_APP_AWS_BUCKET,
      Key: sanitizedFileName,
      Body: selectedFile,
      ContentType: selectedFile.type
    };

    try {
      const data = await s3.upload(params).promise();
      setMessage(`File uploaded successfully. ${data.Location}`);
      setImageUrl(`${config.REACT_APP_CLOUDFRONT_URL}/${sanitizedFileName}`); // Use CloudFront URL
      setFileKey(selectedFile.name); // Store the file key for deletion
    } catch (error) {
      setMessage(`File upload failed: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    if (!fileKey) {
      setMessage('No image to delete.');
      return;
    }

    const params = {
      Bucket: config.REACT_APP_AWS_BUCKET,
      Key: fileKey // Use the stored file key
    };

    try {
      await s3.deleteObject(params).promise();
      setMessage('File deleted successfully.');
      setImageUrl(''); // Clear the image URL
      setFileKey(''); // Clear the file key
      setSelectedFile(null); // Clear the selected file
    } catch (error) {
      setMessage(`File deletion failed: ${error.message}`);
    }
  };

  return (
    <div className="form aws-image-upload">
      <input type="file" onChange={handleFileChange} />
      <button className="button-cta button-cta-form" onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
      {imageUrl && (
        <div>
          <p>Uploaded Image URL: <a href={imageUrl} target="_blank" rel="noopener noreferrer">{imageUrl}</a></p>
          <button className="button-cta button-cta-warning" onClick={handleDelete}>Delete</button>
        </div>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default ImageUpload;