import React, { useState, useEffect } from 'react';

const ConnectionStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div className='connection-status'>
      {/*isOnline ? (
        <p>You are online.</p>
      ) : (
        <p>You are offline. Please check your internet connection.</p>
      )*/}
      {!isOnline && <p>You are offline. Please check your internet connection.</p>}
    </div>
  );
};

export default ConnectionStatus;