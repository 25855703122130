/* Layout.js */

import React, { useContext } from "react";
import { Context } from "../Context";
import { useNavigate, useLocation } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import StatusBadge from "../components/Badges/StatusBadge";
import PageControls from "../components/PageControls";
import Footer from "../components/Footer";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dictionary, setIsEditMode } = useContext(Context);
  const isHomePage = location.pathname === "/";
  const pathnameArr = location.pathname.split("/");

  const moveHome = () => {
    setIsEditMode(false);
    navigate("/Home");
  };

  return (
    <div className={"container " + (pathnameArr[1] && "container_" + pathnameArr[1]) + " unselectable"} >
      <header>
        <h1>
          <Link to={"/"} onClick={moveHome}>
            <span dangerouslySetInnerHTML={{ __html: dictionary.meta.title }} />
          </Link>
        </h1>
        <StatusBadge />
      </header>
      {!isHomePage && <PageControls position="top" />}
      <Outlet />
      {!isHomePage && <PageControls />}
      <Footer />
    </div>
  );
};
export default Layout;
