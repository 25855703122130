/* Disclaimer.js */

import React, { useContext } from "react";
import { Context } from '../Context';

const Disclaimer = () => { 
  const { dictionary } = useContext(Context);
  return <main className = "screen" dangerouslySetInnerHTML = { {__html: dictionary.pages.disclaimer } } /> 
};

export default Disclaimer;