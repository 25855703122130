/* Spice.js */

import React, { lazy, Suspense, useState } from 'react'
import { renderLoader } from "../utils/renderLoader";

const Screen = lazy(() => import('./../components/Screen'));
const Slider = lazy(() => import('./../components/Slider'));

const Spice = () => {
  const [ filter, setFilter ] = useState("")
  
  return <main>
      <Suspense fallback={renderLoader()}>
        <Screen table="spice" setFilter={ setFilter } />
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <Slider table="spice" filter={ filter } />
      </Suspense>
  </main>};

export default Spice;