/* Footer.js */

import React, { useContext } from "react";
import { Context } from "../Context";
import { Link } from "react-router-dom";

const Footer = () => {
  const { dictionary } = useContext(Context);

  return (
    <footer>
      <FooterLinks />
      <FooterCopyright text={dictionary.config.copyright} />
    </footer>
  );
};

const FooterLinks = () => (
  <div>
    <Link to="/about">About</Link>
    <Link to="/disclaimer">Disclaimer</Link>
  </div>
);

const FooterCopyright = ({ text }) => (
  <p dangerouslySetInnerHTML={{ __html: text }} />
);

export default Footer;
