/* Sandbox.js */

import React, { useContext } from "react";
import { Context } from "../Context";
import ImageUpload from "../components/AWS/ImageUpload";

const Sandbox = () => { 
  const { profile } = useContext(Context);
  return <main className="screen">
        { profile.status === "active" &&  <>
          <h2>Image Upload</h2>
          <ImageUpload />
        </>}
      </main>
};

export default Sandbox;