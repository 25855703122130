/* Country.js */

import React, { lazy, Suspense, useState } from 'react';
import { renderLoader } from "../utils/renderLoader";

const Slider = lazy(() => import('./../components/Slider'));
const Screen = lazy(() => import('./../components/Screen'));

const Country = () => {
  const [ filter, setFilter] = useState("")
  return <main>
      <Suspense fallback = { renderLoader() }>
        <Screen table = "country" setFilter = { setFilter } />
      </Suspense>
      <Suspense fallback={ renderLoader() }>
        <Slider table = "dish" filter = { filter } />
      </Suspense>
    </main>};

export default Country;