/* Home.js */

import React, { lazy, Suspense } from 'react';
import { renderLoader } from "../utils/renderLoader";
import ConnectionStatus from "../components/ConnectionStatus";

const Slider = lazy(() => import('../components/Slider'));

const Home = () => { 
  return <Suspense fallback={renderLoader()}>
      <main>
        <Slider table="dish"  />
        <Slider table="spice" />
        <ConnectionStatus />
      </main>
  </Suspense>}

export default Home;