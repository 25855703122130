function EmailInputField({
  handleValidation,
  handleEmailChange,
  emailValue,
  emailError,
}) {
  return (
    <>
      <input
        autoComplete="off"
        name="email"
        onChange={handleEmailChange}
        onBlur={handleValidation}
        placeholder="Email"
        type="text"
        value={emailValue}
      />
      <em className="text-danger">{emailError}</em>
    </>
  );
}

export default EmailInputField;
