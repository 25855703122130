function PasswordInputField({
  handleValidation,
  handlePasswordChange,
  passwordValue,
  passwordError,
}) {
  return (
    <>
      <input
        autoComplete="off"
        name="password"
        onChange={handlePasswordChange}
        onBlur={handleValidation}
        placeholder="Password"
        type="password"
        value={passwordValue}
      />
      <em className="text-danger">{passwordError}</em>
    </>
  );
}

export default PasswordInputField;
