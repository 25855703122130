/* PageControls.js */

import React, { useContext } from "react";
import { Context } from "../Context";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { RiPrinterFill } from "react-icons/ri";
import { BiSolidToTop } from "react-icons/bi";
import { IoHome } from "react-icons/io5";

const PageControls = ({ position }) => {
  const navigate = useNavigate();
  const { dictionary } = useContext(Context);

  const handleClickHome = () => {
    navigate("/");
  };

  const handleClickTop = () => {
    window.scrollTo(0, 0);
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <section className="page-controls">
      {position === "top" ? (
        <IoHome
          aria-label={dictionary.button.homeAria}
          onClick={handleClickHome}
        >
          {dictionary.button.home}
        </IoHome>
      ) : (
        <TiArrowBack
          aria-label={dictionary.button.backAria}
          onClick={handleClickBack}
        >
          {dictionary.button.back}
        </TiArrowBack>
      )}
      <div>
        {position !== "top" && (
          <BiSolidToTop
            aria-label={dictionary.button.topAria}
            onClick={handleClickTop}
          >
            {dictionary.button.top}
          </BiSolidToTop>
        )}
        <RiPrinterFill
          aria-label={dictionary.button.printAria}
          onClick={() => window.print()}
        >
          {dictionary.button.print}
        </RiPrinterFill>
      </div>
    </section>
  );
};

export default PageControls;
