/* Basket.js */

import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Context } from "../Context";
import { useNavigate } from "react-router-dom";
import { renderLoader } from "../utils/renderLoader";
//import OnlineStatus from "../components/OnlineStatus"

const ListUserSavedItem = lazy(() => import("../components/Lists/ListUserSavedItem"));

const Basket = () => {
  const { profile, dictionary } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.username) {
      navigate("/login");
    }
  }, [navigate, profile]);

  return (
    <main className="screen">
      <BasketHeader heading={dictionary.config.headingBasket} prompt={dictionary.config.promptBasket} />
      {/*<OnlineStatus />*/}
      <Suspense fallback={renderLoader()}>
        <BasketContent heading={dictionary.config.headingBasketIngredients} />
      </Suspense>
    </main>
  );
};

const BasketHeader = ({ heading, prompt }) => (
  <>
    <h2 dangerouslySetInnerHTML={{ __html: heading }} />
    <p>
      <em dangerouslySetInnerHTML={{ __html: prompt }} />
    </p>
  </>
);

const BasketContent = ({ heading }) => (
  <>
    <h3 dangerouslySetInnerHTML={{ __html: heading }} />
    <ListUserSavedItem isBasket={true} table="basket/ingredients" />
  </>
);

export default Basket;