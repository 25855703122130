/* idle.js */

import { useContext } from "react";
import { Context } from "../Context";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

// Single Responsibility Principle: Separate the idle handler logic
function useIdleHandler() {
  const { setProfile, setStaleUserSaved, setStaleUserCreate } = useContext(Context);
  const navigate = useNavigate();

  const handleIdle = () => {
    setStaleUserSaved(true);
    setStaleUserCreate(true);
    setProfile({});
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    navigate('/login');
  };

  return handleIdle;
}

export default function Idle() {
  const handleIdle = useIdleHandler();

  // Open/Closed Principle: Use a constant for the timeout duration
  const TIMEOUT_DURATION = 15 * 60 * 1000;

  useIdleTimer({ onIdle: handleIdle, timeout: TIMEOUT_DURATION });

  return null; // Ensure the component does not render any UI
}