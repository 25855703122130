/* Verify.js */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { config } from "../config";
import { Context } from "../Context";
import { headersBasic } from "../utils/headers";
import { Link } from "react-router-dom";
import { PASSWORD_REGEX } from "../utils";
import PasswordInputField from "../components/Account/PasswordInputField";
import PasswordInputFieldConfirm from "../components/Account/PasswordInputFieldConfirm";
import Button from "../components/Button";
import bcrypt from "bcryptjs";


const Verify = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [type, setType] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { salt } = useContext(Context);
  const formStateDefault = { password: "", confirmPassword: ""}
  const errorsDefault = { password: "*Required", confirmPassword: "*Required"}
  const [formState, setFormState] = useState(formStateDefault);
  const [errors, setErrors] = useState(errorsDefault);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if(type) {
      switch (type) {
        case "recovery":
          setDisabled(errors.password, errors.confirmPassword);
          break;
        default:
          break;
      }
    }
  }, [type, errors]);

  // First Check if a matching code exists 
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      fetch(`${config.apiRoot}/verify/${code}`, {
        method: "GET",
        headers: headersBasic
      }).then((response) => response.json())
        .then((data) => { 
          // Handle success response
          setLoading(false);
          setType(data)
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
        
    } else {
      setLoading(false);
      setError("code not found");
    }
  }, [location.search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message || error}</div>;
  }

  const validatePassword = (password) => {
    if (password.length === 0) return "Password is empty";
    if (!PASSWORD_REGEX.uppercase.test(password)) return "At least one Uppercase";
    if (!PASSWORD_REGEX.lowercase.test(password)) return "At least one Lowercase";
    if (!PASSWORD_REGEX.digits.test(password)) return "At least one digit";
    if (!PASSWORD_REGEX.specialChar.test(password)) return "At least one Special Character";
    if (!PASSWORD_REGEX.minLength.test(password)) return "At least minimum 8 characters";
    return "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value.trim() }));
  };

  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMsg = "";
    switch (name) {
      case "password":
        errorMsg = validatePassword(value);
        setErrors((prevState) => ({ ...prevState, password: errorMsg }));
        break;
      case "confirmPassword":
        errorMsg = value !== formState.password ? "Confirm password is not matched" : "";
        setErrors((prevState) => ({ ...prevState, confirmPassword: errorMsg }));
        break;
      default:
        break;
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const url = config.apiRoot + `/changepassword`;
    const hashedPassword = bcrypt.hashSync(formState.password.trim(), salt);
    const rawResponse = await fetch(url, {
      method: "PUT",
      headers: headersBasic,
      body: JSON.stringify({
        email: type[1],
        password: hashedPassword,
      })
    });
    await rawResponse.json();
    navigate("/login")
  };

  return (
    <main className="screen ">
     
      { type[2] === "registration" && <>
          <h2>Confirm Email</h2>
          <p>Thankyou for Confirming your Email.</p>
          <p>You can now <Link to="/Login">Login</Link> to Access your Dashboard.</p>
      </>}

      { type[2] === "recovery" && <>
        <h2>Account Services</h2>
        <p>Please update your password.</p>
        <form className="form form-signup" onSubmit={(e) => handleChangePassword(e)}>
          <PasswordInputField
            handlePasswordChange={handleInputChange}
            handleValidation={handleValidation}
            passwordValue={formState.password}
            passwordError={errors.password}
          />
          <PasswordInputFieldConfirm
            handlePasswordChange={handleInputChange}
            handleValidation={handleValidation}
            confirmPasswordValue={formState.confirmPassword}
            confirmPasswordError={errors.confirmPassword}
          />
          <Button disabled={errors.confirmPassword?disabled:""} label="Submit" />
        </form>
      </>}
    </main>
  );
};

export default Verify;
