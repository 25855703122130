import { useContext, useState, useEffect } from "react";
import { Context } from "../../Context";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";
import CountryInputField from "./CountryInputField";
import NameInputField from "./NameInputField";
import { headersBearer } from "../../utils/headers";
import useFetch from "../../hooks/useFetch";
import { Link } from "react-router-dom";

function DishForm(props) {
  const navigate = useNavigate();
  const { journey, currID } = props;
  const { profile } = useContext(Context);
  const [data, setData] = useState();
  const [countrys] = useFetch(config.apiRoot + `/country`);
  const [countryInput, setCountryInput] = useState({ country: "" });
  const [countryError, setCountryErr] = useState("Country Required");
  const [nameInput, setNameInput] = useState({ name: "" });
  const [nameError, setNameErr] = useState("Name Required");
  const [disabled, setDisabled] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const delayLength = 100;
  const username = profile.username;
  const dishUrl = config.apiRoot + `/dish/user/` + username + `/` + currID;

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    journey === "update" && setIsEdit(true);
  }, [journey, setIsEdit, isEdit]);

  useEffect(() => {
    if (currID && !isNaN(+currID) && username) {
      async function makeRequest() {
        await delay(delayLength);
        getData(dishUrl);
      }
      makeRequest();
      const getData = async (apiUrl) => {
        fetch(apiUrl, { method: "GET", headers: headersBearer })
          .then((response) => {
            if (!response.ok) {
              console.log(` ${response.statusText}`);
            }
            return response.json();
          })
          .then((json) => setData(json))
          .catch((error) => {
            console.log(` ${error.message}`);
          });
      };
    }
  }, [dishUrl, currID, username]);

  useEffect(() => {
    switch (journey) {
      case "update":
      case "create":
        setDisabled(nameError || countryError);
        break;
      default:
    }
  }, [journey, nameError, countryError]);

  const handleInputChange = (evnt) => {
    const currentTarget = evnt.target;
    const inputValue = currentTarget.value;
    const inputFieldName = currentTarget.name;
    let newInput;
    switch (inputFieldName) {
      case "name":
        newInput = { ...nameInput, [inputFieldName]: inputValue };
        setNameInput(newInput);
        break;
      case "country":
        newInput = { ...countryInput, [inputFieldName]: inputValue };
        setCountryInput(newInput);
        break;
      default:
      // code block
    }
    handleValidation(evnt);
  };
  const handleValidation = (evnt) => {
    const inputValue = evnt.target.value.trim();
    const inputFieldName = evnt.target.name;
    if (inputFieldName === "name") {
      const nameLength = inputValue.length;
      let errMsg = "";
      if (nameLength === 0) {
        errMsg = "Name is empty";
      } else {
        errMsg = "";
      }
      setNameErr(errMsg);
    }
    if (inputFieldName === "country") {
      const countryValue = inputValue;
      let errMsg = "";
      if (countryValue <= 1) {
        errMsg = "Country Required";
      } else {
        errMsg = "";
      }
      setCountryErr(errMsg);
    }
    if (inputFieldName === "create") {
      (async () => {
        const url = config.apiRoot + `/dish`;
        const rawResponse = await fetch(url, {
          method: "POST",
          headers: headersBearer,
          body: JSON.stringify({
            name: nameInput.name.trim(),
            country_ID: parseInt(countryInput.country),
            username: profile.username,
          }),
        });
        await rawResponse.json();
        navigate("/dashboard");
      })();
    }
    if (inputFieldName === "update") {
      (async () => {
        const url = config.apiRoot + `/dish/` + currID;
        const rawResponse = await fetch(url, {
          method: "PUT",
          headers: headersBearer,
          body: JSON.stringify({
            name: nameInput.name.trim(),
            country_ID: parseInt(countryInput.country),
          }),
        });
        await rawResponse.json();
        navigate("/dashboard");
      })();
    }
  };

  function handleEvent(e) {
    handleValidation(e);
  }

  return (
    <div className="form form-create">
      <h2>{journey} Dish</h2>
      <p>
        {(journey === "create" || "update") && (
          <>
            <NameInputField
              handleNameChange={handleInputChange}
              handleValidation={handleValidation}
              nameValue={nameInput.name}
              nameError={nameError}
              dbValue={isEdit && currID && data && data.name}
            />
            <CountryInputField
              handleCountryChange={handleInputChange}
              handleValidation={handleValidation}
              countryValue={countryInput.name}
              countryError={countryError}
              countrys={countrys}
            />
          </>
        )}
        <button
          className="capitalize"
          value={journey}
          name={journey}
          id={journey}
          disabled={disabled}
          onClick={(e) => handleEvent(e)}
        >
          {journey}
        </button>
        &nbsp;&nbsp;or <Link to="/dashboard">Cancel</Link>
      </p>
    </div>
  );
}
export default DishForm;
