/* DishForm/CountryInputField.js */

function CountryInputField(props) {
  const { handleCountryChange, countryError, countrys } = props;
  return (
    <>
      <select
        type="text"
        onChange={handleCountryChange}
        onFocus={handleCountryChange}
        onBlur={handleCountryChange}
        name="country"
      >
        <option value="">Country Name</option>
        {countrys &&
          countrys.map((country, index) => {
            return (
              <option key={index} value={country.country_ID}>
                {country.name}
              </option>
            );
          })}
      </select>
      <em className="text-danger">{countryError}</em>
    </>
  );
}

export default CountryInputField;
