/* Dashboard.js */

import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Context } from "../Context";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import Heading from "../components/Heading";
import dictionary from "../dictionary.js";
import { renderLoader } from "../utils/renderLoader";

const ListUserCreatedDish = lazy(() =>
  import("../components/Lists/ListUserCreatedDish")
);
const ListUserSavedItem = lazy(() =>
  import("../components/Lists/ListUserSavedItem")
);

const Dashboard = () => {
  const { profile, setAwardCount, setCostBasket } = useContext(Context);

  useEffect(() => {
    setAwardCount(0)
    setCostBasket(0.0)
  }, [setAwardCount, setCostBasket]);

  return (
    <main className="screen">
      <Heading Tag="h2" title={dictionary.config.headingDashboard} />

      {profile.status === "active" ? (
        <>
          <Heading Tag="h3" title={dictionary.config.headingSavedDishList} />

          <Suspense fallback={renderLoader()}>
            <ListUserSavedItem table="saved" />
            <Button
              action="handleBasket"
              label={dictionary.button.addToBasket}
            />
          </Suspense>

          <Heading Tag="h3" title={dictionary.config.headingCreatedDishList} />

          <Suspense fallback={renderLoader()}>
            <ListUserCreatedDish table="dish" />
          </Suspense>

          <div>
            <Button action="handlePostDish" label="Create Dish" />
            <br />
            <br />
            <Button
              action="handleLogout"
              addClasses="button-cta-warning button-cta-logout"
              label={dictionary.button.logout}
            />
          </div>
        </>
      ) : (
        <p>
          Return to <Link to={"/"}>Homepage</Link>, or <Link to={"/login/"}>login</Link>.
        </p>
      )}
    </main>
  );
};

export default Dashboard;
