/* Measure.js */

import { config } from '../config';
import useFetch from "../hooks/useFetch";

const Measure = () => { 
    const [ data ] = useFetch( config.apiRoot + `/unit` )
    return <main className="screen">
        <h2>Measures</h2>
        <ul>
            {data && data.map((item, i) => <li key={i}>
                {item.code} {" - " +item.name}
            </li>)} 
        </ul>
    </main>
  };

export default Measure;

/* 
    Measurements and conversions
    teaspoon	teaspoon
    tablespoon	tbsp/tbs
    oz	oz
    gram	g
    fluid oz	fl oz
    pound	1lb
    kilogram	kg
    milliter	ml
    litre	l
    cup	c
    quart	qt
    gallon	gl
    pint	pt
    Measuring Accurately

    Metric	Measuring Spoons
    1.25ml	¼ teaspoon
    2.5ml	½ teaspoon
    5ml	1 teaspoon
    15ml	1 tablespoon or 3 teaspoons

    Metric	Measuring Cups
    60ml	¼ cup or 4 tablespoons
    80ml	⅓ cup
    120ml	½ cup or 8 tablespoons
    240ml	1 cup or 16 tablespoons

    Metric	Imperial
    7 grams	¼ ounce
    14 grams	½ ounce
    28 grams	1 ounce
    57 grams	2 ounces
    113 grams	4 ounces (¼ lb)
    170 gram	6 ounces
    227 grams	8 ounces (½ lb)
    454 grams	16 ounces (1 lb)
    907 grams	32 ounces (2lb)

    Fan Celsus	Celsius	Fahrenheit	Gas Mark
    120 ° C	140 ° C	275 ° F	1
    130 ° C	150 ° C	300 ° F	2
    150 ° C	170 ° C	325 ° F	3
    160 ° C	180 ° C	350 ° F	4
    170 ° C	190 ° C	375 ° F	5
    180 ° C	200 ° C	400 ° F	6
    200 ° C	220 ° C	425 ° F	7
    210 ° C	230 ° C	450 ° F	8
    220 ° C	240 ° C	475 ° F	9
    240 ° C	260 ° C	500 ° F	10
*/