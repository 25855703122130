
/* DishCreate.js */

import { useContext, useEffect } from 'react'
import { Context } from '../Context';
import { useNavigate } from "react-router-dom";
import DishForm from "../components/DishForm";

const DishCreate = () => { 
  const navigate = useNavigate();
  const { profile } = useContext(Context);
  const arrUrl = window.location.pathname.split('/');
  const currID = arrUrl[arrUrl.length - 1]

  useEffect(() => {
    profile.username === undefined && navigate("/login");
  }, [navigate, profile]);
  
  return <main className="screen">
      <DishForm currID={ currID } journey={ (currID && !isNaN(+currID)) ? 'update' : 'create'} />
  </main>;
}

export default DishCreate;