/* Recover.js */

import React from "react";
import Account from "../components/Account";

const Recover = () => { return <main className="screen">
        <Account journey='recover' />
      </main>
};

export default Recover;