export const headersBasic = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export const headersBearer = {
  'Authorization' : 'Bearer ' + localStorage.getItem('token'),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export const headersBasicMultipart = {
  'Accept': 'application/xhtml+xml,application/xml,application/text/html',
  'Content-Type': 'multipart/form-data'
};

export const headersBearerMultipart = {
  'Accept': 'image/png, image/jpg, image/jpeg, image/webp',
  'Content-Type': 'multipart/form-data',
  'Authorization' : 'Bearer ' + localStorage.getItem('token')
}