/* index.js */

import { StrictMode } from 'react';
import ReactDOM from "react-dom/client";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Context } from "./Context";
import Background from "./components/Background/Background";
import About from "./pages/About";
import Basket from "./pages/Basket";
import Country from "./pages/Country";
import Dashboard from "./pages/Dashboard";
import Disclaimer from "./pages/Disclaimer";
import Dish from "./pages/Dish";
import DishCreate from "./pages/DishCreate";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Measure from "./pages/Measure";
import NoPage from "./pages/NoPage";
import Recover from "./pages/Recover";
import Search from "./pages/Search";
import Signup from "./pages/Signup";
import Spice from "./pages/Spice";
import Verify from "./pages/Verify";
import Sandbox from "./pages/Sandbox";
import ScrollToTop from "./components/ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import Idle from "./components/Idle";
import dictionary from "./dictionary.js";
import { config } from "./config";
import "./App.css";

export default function App() {
  const salt = config.salt;
  const [profile, setProfile] = useState({});
  const [awardCount, setAwardCount] = useState(0);
  const [costBasket, setCostBasket] = useState(0.0);
  const [isStaleUserSaved, setStaleUserSaved] = useState(true);
  const [isStaleUserCreate, setStaleUserCreate] = useState(true);
  const [isIngredientDataStale, setIsIngredientDataStale] = useState(true);
  const [isSectionDataStale, setIsSectionDataStale] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputs, setInputs] = useState([{
    heading: "",
    category: "",
    description: "",
    image: "",
    position: "",
    body: "",
    ingredients: [],
  }]);

  return (
    <BrowserRouter>
      <Context.Provider
        value={{
          dictionary,
          profile,
          setProfile,
          awardCount,
          setAwardCount,
          costBasket,
          setCostBasket,
          isStaleUserSaved,
          setStaleUserSaved,
          isStaleUserCreate,
          setStaleUserCreate,
          isIngredientDataStale,
          setIsIngredientDataStale,
          isSectionDataStale,
          setIsSectionDataStale,
          isEditMode,
          setIsEditMode,
          inputs,
          setInputs,
          salt
        }}
      >
        <Background />
        <ScrollToTop />
        <Idle />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/basket" element={<Basket />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/country/:countryId" element={<Country />} />
            <Route path="/dish/:dishId" element={<Dish />} />
            <Route path="/dish/edit/:dishId" element={<Dish />} />
            <Route path="/dish/create" element={<DishCreate />} />
            <Route path="/dish/create/:dishId" element={<DishCreate />} />
            <Route path="/login" element={<Login />} />
            <Route path="/measure" element={<Measure />} />
            <Route path="/recover" element={<Recover />} />
            <Route path="/search-dish" element={<Search table="dish" />} />
            <Route path="/search-spice" element={<Search table="spice" />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/spice/:spiceId" element={<Spice />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/sandbox" element={<Sandbox />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </Context.Provider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
