/* About.js */

import React, { useContext } from "react";
import { Context } from '../Context';

const About = () => { 
  const { dictionary } = useContext(Context);
  return (
    <main className="screen">
      <AboutContent content={dictionary.pages.about} />
    </main>
  );
};

const AboutContent = ({ content }) => (
  <section dangerouslySetInnerHTML={{ __html: content }} />
);

export default About;

/*
SRP: Separated the content rendering into its own component AboutContent.
OCP: The About component can now be extended by adding more sections or features without modifying the existing code.
LSP: The AboutContent component can be replaced or extended without affecting the About component.
ISP: Created a specific component for rendering the content.
DIP: The About component depends on the AboutContent abstraction.
*/
