import { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { config } from '../config';
import useFetch from "../hooks/useFetch";

const SearchList = (props) => {
  const ref = useRef(null);
  const { table } = props
  const [ filterName, setFilterName] = useState("")
  const [ filterType, setFilterType] = useState("")
  const [ data ] = useFetch( config.apiRoot + `/` + table )
  
  let clonedData = data && data.slice();
  if(filterName) { clonedData = clonedData.filter(item => item.name.toLowerCase().includes(filterName.toLowerCase()))}
  if(filterType) { clonedData = clonedData.filter(item => item.type.toLowerCase() === filterType.toLowerCase())}

  const changeFilterName = (e) => {
    setFilterName(e.target.value)
  }

  const changeFilterType = (e) => {
    setFilterType(e.target.value)
  }

  return (
    <div className="form form-search">
      <p>
        <label>
          <input id="name" onChange = {changeFilterName} placeholder="Search" ref = { ref } />
        </label>

        {table === "spice" && <>
          <label><input type = "radio" value = "Herb" checked = {filterType === 'Herb'} onChange = {changeFilterType} />Herb</label>
          <label><input type = "radio" value = "Seasoning" checked = {filterType === 'Seasoning'} onChange={changeFilterType} />Seasoning</label>
          <label><input type = "radio" value = "Spice" checked = {filterType === 'Spice'} onChange={changeFilterType} />Spice</label>
        </>}
      </p>

      <ul>
        {clonedData && clonedData.map(( item, i ) => <li key = { i }>
          <Link to={"/" + table + "/" + (table === "spice" ? item.spice_ID : item.dish_ID) }>
            { item.name } {/* item.type && "- " + item.type */}
          </Link> 
        </li>)} 
      </ul>

    </div>
)};
  
export default SearchList;