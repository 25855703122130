function TokenInputField({
  handleValidation,
  handleTokenChange,
  tokenValue,
  tokenError,
}) {
  return (
    <>
      <input
        autoComplete="off"
        name="token"
        onChange={handleTokenChange}
        onBlur={handleValidation}
        placeholder="Token"
        type="text"
        value={tokenValue}
      />
      <em className="text-danger">{tokenError}</em>
    </>
  );
}

export default TokenInputField;
