/* DishForm/NameInputField.js */

function NameInputField(props) {
  const { handleValidation, handleNameChange, nameError, dbValue } = props;
  return (
    <>
      <input
        type="text"
        onChange={handleNameChange}
        onBlur={handleValidation}
        name="name"
        placeholder={dbValue ? dbValue : "Dish Name"}
      />
      <em className="text-danger">{nameError}</em>
    </>
  );
}

export default NameInputField;
