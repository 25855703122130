import React, { useState, useEffect } from "react";
import background_1 from "./spice-bg_240x240.webp";
import background_2 from "./pepper-bg_240x240.webp";
import background_3 from "./annato-bg_240x240.webp";

const images = [background_1, background_2, background_3];

const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

const Background = () => {
  const [selectedImage, setSelectedImage] = useState(getRandomImage());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedImage((prevImage) => {
        const nextImage = prevImage === images[0] ? images[1] : images[0];
        return nextImage;
      });
    }, 40000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      data-testid="background"
      className="background"
      style={{
        opacity: "0.1",
        backgroundImage: `url(${selectedImage})`,
      }}
    ></div>
  );
};

export default Background;