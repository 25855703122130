function NameInputField({
  handleValidation,
  handleNameChange,
  nameValue,
  nameError,
}) {
  return (
    <>
      <input
        autoComplete="off"
        name="name"
        onChange={handleNameChange}
        onBlur={handleValidation}
        placeholder="Name"
        type="text"
        value={nameValue}
      />
      <em className="text-danger">{nameError}</em>
    </>
  );
}

export default NameInputField;
